.list-row {
	border-radius: 4px;
	background-color: #ffffff;
	border: solid 1px #e6eaee;
	display: flex;
	flex-wrap: nowrap;
	margin: 10px 30px 0px 30px;
	padding: 20px 30px 20px 30px;
}

h2.value {
  margin: 0;
}

table {
	text-align: left;

	// border-collapse: collapse;
	border-collapse: separate;
  border: 1px solid #dfe2e5;
	border-bottom: 0px;
	border-spacing: 0px;
  border-radius: 4px;
  -moz-border-radius: 4px;

	&.full-width {
		width: 100%;
	}

	tr {
		td {
			border-top: 1px solid #dfe2e5;
			padding: 15px 9px;
		}

		.value {
			font-size: 14px;
			font-weight: 600;
			color: #354052;

			&h2 {
				font-size: 16px;
			}
		}

		.label {
			font-size: 12px;
			color: #7f8fa4;
		}
	}
}


thead {
	tr {
		border-radius: 3px;
		background-color: #f5f8fa;
		height: 36px;

		th {
			font-size: 14px;
			font-weight: 400;
			color: #7f8fa4;
			padding: 9px;
			border: none;
		}
	}
}

.list-table {
	.row.selected,
	.row:hover {
		background: #f8fafc;
	}
	.row {
		* > a {
			text-decoration: none;
			color: #58b4f9;
		}
	}
	.load-signature,
	.load-signature-image,
	.unload-signature,
	.unload-signature-image {
		img {
			background: #ffffff;
		}
	}
}

@media screen and (max-width: 900px) {
	.mobile-list {
    position: absolute;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: #ffffff;
    width: 100%;
    top: 140px;
    bottom: 0;
    margin-bottom: 44px;
    padding-bottom: 160px;
    .slot {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 84px;
      margin-bottom: 0;
      padding: 15px;
      border: none;
      border-bottom: solid 1px #e7e9ed;
      border-radius: none;
      .icon, .info, .actions {
        display: flex;
        justify-content: center;
      }
      .icon {
        width: 50px;
        height: 50px;
        margin-right: 15px;
        border-radius: 50%;
        background-color: #2a81d7;
        overflow: hidden;
        color: white;
        align-items: center;
        span {
          font-size: 24px;
          margin-top: -2px;
        }
        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          object-position: center;
        }
      }
      .info, .actions {
        flex-direction: column;
        h3, h4, h5 { margin: 0; }
        h3 {
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 6px;
        }
        h4, h5 {
          font-size: 13px;
          font-weight: 500;
          color: #b1b3b5;
        }
        h5 { font-style: italic; }
      }
      .actions {
        cursor: pointer;
        justify-self: flex-end;
        align-self: center;
        margin-left: auto;
        font-size: 40px;
        text-align: right;
        color: rgb(178,178,178);
        .icon-delete {
          font-size: 28px;
          margin-right: 12px;
        }
        .radio-button {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: rgb(230,230,230);
          border: none;
          margin: 0 15px 20px 0;
          padding: 0;
          &.active {
            border: rgb(0, 200, 83) 7px solid;
            background-color: white;
          }
          &:focus, &:active {
            outline: none;
          }
        }
      }

      &.empty {
        .icon {
          background-color: rgb(0, 200, 83);
          font-size: 34px;
        }
        & > div {
          pointer-events: auto;
        }
      }

      &.filled {
        cursor: default;
      }
    }
  }

  .bottom-action {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 86px;
    padding: 15px 25px 25px;
    border-top: 1px solid rgb(242, 242, 242);
    background-color: white;
    button {
      width: 100%;
      height: 100%;
      color: white;
      background-color: rgb(0, 200, 83);
      border: none;
      border-radius: 3px;
      font-size: 14px;
      cursor: pointer;
      &:focus, &:active {
        outline: none;
      }
    }
  }
}
