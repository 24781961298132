// labels
@mixin label($color) {
  height: 20px;
  border-radius: 3px;
  background-color: $color;
  color: #fff;
  display: inline-block;
  margin-right: 1em;
  padding: 4px 10px;
  vertical-align: middle;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
}
.label-success {
  @include label($green);
}
.label-info,
.label-working,
.label-accepted,
.label-paid {
  @include label($blue);
}
.label-danger,
.label-rejected,
.label-declined {
  @include label($red);
}
.label-secondary {
  @include label($dark_gray);
}
.label-warn,
.label-waiting,
.label-pending {
  @include label($orange);
}
.label-disabled {
  @include label($light_gray);
  color: #354052;
}
.label-expired,
.label-cancelled,
.label-upcoming {
  @include label($cancelled_gray);
  color: #ffffff;
}
.yet-another-label-color {
  @include label($light_gray);
}
.label-edited,
.label-expensed {
  @include label($edit_blue);
}
.label-void {
  @include label($void_red);
}
.label-retake {
  @include label($retake_orange);
}
.label-finalized {
  @include label($finalized_green);
}
