.md-dialog-container,
.mat-dialog-container {
  padding: 0 !important;
  overflow: visible !important;
  border-radius: 4px !important;
  height: auto !important;
  min-height: 100%;
  .dropdown {
    width: 100%;
  }
}

md-dialog-container,
.md-dialog-container,
mat-dialog-container,
.mat-dialog-container {
  max-width: 100VW;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.90;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0,0,0,0.90);
}

md-dialog-container,
mat-dialog-container {
  [mat-dialog-content],
  [md-dialog-content],
  mat-dialog-content,
  md-dialog-content {
    display: block;
    padding: 0 30px;
    overflow: visible;
    margin: 0;
    flex: 1 0 auto;
    padding-bottom: 20px;
    max-height: none;
  }

  p {
    text-align: left;
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    color: #72839b;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .alert-success {
    h2 {
      text-align: center;
      color: #333;
      font-size: 22px;
      font-weight: 300;
      margin: 17px 0 0;
    }
  }


  .modal-title {
    margin: 0;
    background-color: #2a81d7;
    min-height: 60px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 4px 4px 0 0;
    h1 {
      font-size: 18px;
      font-weight: 400;
      margin: 0;
      color: #ffffff;
    }

    .close-action {
      position: absolute;
      top: 16px;
      right: 22px;
      color: rgba(255, 255, 255, 0.4);
      font-size: 28px;

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .modal-footer {
    min-height: 60px;
    background-color: #f0f3f8;
    padding: 12px 30px;
    text-align: center;
    display: flex;
    border-radius: 0 0 4px 4px;
    .btn, ruckit-button {
      flex: 1 1 0;
      margin: 0 0 0 20px;
      &:first-child {
        margin: 0;
      }
    }
  }
}
.cdk-global-overlay-wrapper {
  overflow: auto;
}

@media only screen and (min-device-width : 300px) and (max-device-width : 900px) {
  md-dialog-container,
  .md-dialog-container,
  mat-dialog-container,
  .mat-dialog-container {
    width: 100%;
    height: 100%;
    // max-width: 100%;
    max-width: 100VW;
    border-radius: 0;
    overflow: scroll;

    .modal-title {
      border-radius: 0;
    }

    form { height: 100VH !important; }
    md-dialog-content,
    mat-dialog-content {
      overflow: scroll;
      max-height: calc(100VH - 120px);
    }

    confirm-drivers-dialog form {
      height: 100% !important;
    }

  }
}
