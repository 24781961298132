.action-bar-content {
  min-height: 54px;
  flex-wrap: wrap-reverse;
}

action-bar {
  md-toolbar,
  mat-toolbar {
    margin-bottom: 20px;
    padding: 0;
    height: unset !important;
    min-height: unset !important;
  }

  .mat-toolbar-single-row {
    height: unset !important;
    z-index: 1000;
    position: relative;
  }

  .mat-toolbar {
    background: none;
    padding: 0;
  }

  .mat-toolbar-row {
    height: unset;
  }
  &.transparent mat-toolbar {
    background: none;
    .action-bar-content {
      box-shadow: none;
    }
  }
}
action-bar md-toolbar-row,
action-bar .mat-toolbar-single-row {
  height: auto;
}
@media (max-width: 960px) {
  action-bar md-toolbar-row,
  action-bar .mat-toolbar-single-row {
    .action-bar-content {
      padding-bottom: 12px;
      padding-top: 12px;
    }
  }
}
@media (max-width: 500px) {
  action-bar md-toolbar,
  action-bar mat-toolbar {
    margin-bottom: 0;
  }
}

.left-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:center;
  height: 100%;
  font-size: 16px;
  h1.title {
    margin: 12px 30px 0 0;
    color: #2a81d7;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
    flex: 0 0 auto;
    height: 100%;

    color: #7f8fa4;
    margin-right: 30px;
    text-decoration: none;

    &.active {
      margin-bottom: -3px;
      border-bottom: 3px solid #2a81d7;
    }
  }

  .connection-type-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: left;
    flex: 0 0 auto;
    height: 100%;

    color: #7f8fa4;
    margin-right: 30px;
    text-decoration: none;

    &:first-child {
      margin-right: 5px;
    }

    &.active {
      margin-bottom: -3px;
      border-bottom: 3px solid #2a81d7;
    }
  }

  .action-divider {
    color: #7f8fa4;
    margin: 0px 30px;
  }

  .fa {
    margin-left: 5px;
    color: #7f8fa4;
  }
}
.action-bar-content {
  flex-wrap: wrap;
}
.right-actions {
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
  height: 36px;

  .search-container {
    display: inline-block;
    position: relative;
    min-width: 90px;
    .search {
      width: 100%;
      display: inline-block;
    }
  }
  a,
  button {
    margin-left: 17px;
    flex: 0 0 auto;
  }
}

search {
  display: inline-block;
  position: relative;
  .icon-search, .icon-error {
    position: absolute;
    right: 7px;
    margin-top: 8px;
    font-size: 20px;
    cursor: pointer;
    color: #ced0da;
    transform: scale(1.2);
  }
}

// Sub-navigation / tabs
.subnav {
  width: 100%;
  margin-top: 15px;
  .links {
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;

    a {
      position: relative;
      text-decoration: none;
      font-size: 14px;

      &.active {
        border-bottom: 3px solid #2ea2f8;
      }
    }
  }

  &.custom-subnav {
    .links {
      margin: 0;
    }
  }

  .badge {
    position: absolute;
    top: -10px;
    right: -15px;

    min-width: 20px;
  	height: 20px;
    border-radius: 10px;
    padding: 0px 4px;
  	background-color: #ed1c24;

    line-height: 20px;
    text-align: center;
    font-size: 12px;
  	font-weight: 600;
  	color: #ffffff;
  }
}
