@import url(../assets/fonts/icomoon/style.css);
@import url(../../node_modules/timepicker/jquery.timepicker.css);
.btn {
  height: 36px;
  line-height: 32px;
  border-radius: 4px;
  padding: 0 20px 2px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s;
  text-decoration: none; }
  .btn:disabled {
    opacity: 0.3; }
  .btn:focus {
    outline: none; }
  .btn.loading {
    color: rgba(0, 0, 0, 0);
    position: relative; }
    .btn.loading:before {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
      opacity: 1;
      background: url(/assets/img/spinner_22x22.png) center 0 no-repeat transparent;
      animation: loading 0.8s steps(19) infinite;
      content: '';
      display: inline-block;
      height: 22px;
      width: 22px; }
  .btn .icon-plus {
    opacity: 0.6;
    transform: scale(1.5) translate(-2px, 1px);
    display: inline-block; }
  .btn i {
    margin-right: 10px;
    font-size: 15px;
    margin-top: -2px;
    display: inline-block;
    vertical-align: middle; }

@keyframes loading {
  100% {
    background-position: center -418px; } }

@keyframes loading-small {
  100% {
    background-position: center -304px; } }

.btn-primary {
  color: #FFFFFF;
  background-color: #00c853; }
  .btn-primary:hover, .btn-primary:focus {
    background-color: #00e25e; }
  .btn-primary:active {
    background-color: #00af48; }
  .btn-primary:disabled {
    background: #00c853; }
  .btn-primary.loading {
    background-color: #00c853;
    opacity: 1; }
  .btn-primary:disabled {
    background-color: #00c853; }
  .btn-primary .md-button-wrapper,
  .btn-primary .mat-button-wrapper {
    color: #FFFFFF;
    font-size: 16px; }

.btn-primary-alt {
  color: #FFFFFF;
  background-color: #2a81d7; }
  .btn-primary-alt:hover, .btn-primary-alt:focus {
    background-color: #3f8edb; }
  .btn-primary-alt:active {
    background-color: #2574c3; }
  .btn-primary-alt:disabled {
    background: #2a81d7; }
  .btn-primary-alt.loading {
    background-color: #2a81d7;
    opacity: 1; }
  .btn-primary-alt:disabled {
    background-color: #2a81d7; }
  .btn-primary-alt .md-button-wrapper,
  .btn-primary-alt .mat-button-wrapper {
    color: #FFFFFF;
    font-size: 16px; }

.btn-cancel {
  background-color: #666;
  color: #FFFFFF; }
  .btn-cancel:hover, .btn-cancel:focus {
    background-color: #737373; }
  .btn-cancel:active {
    background-color: #595959; }
  .btn-cancel:disabled {
    background: #666; }
  .btn-cancel.loading {
    background-color: #666;
    opacity: 1; }
  .btn-cancel .md-button-wrapper,
  .btn-cancel .mat-button-wrapper {
    color: #FFFFFF;
    font-size: 16px; }

.btn-warning {
  background-color: #F6A623; }
  .btn-warning:hover, .btn-warning:focus {
    background-color: #f7b03b; }
  .btn-warning:active {
    background-color: #f59c0b; }
  .btn-warning:disabled {
    background: #F6A623; }
  .btn-warning.loading {
    background-color: #F6A623;
    opacity: 1; }
  .btn-warning .md-button-wrapper,
  .btn-warning .mat-button-wrapper {
    color: #FFFFFF;
    font-size: 16px; }

.btn-failure {
  background-color: #D0011B; }
  .btn-failure:hover, .btn-failure:focus {
    background-color: #e9011e; }
  .btn-failure:active {
    background-color: #b70118; }
  .btn-failure:disabled {
    background: #D0011B; }
  .btn-failure.loading {
    background-color: #D0011B;
    opacity: 1; }
  .btn-failure .md-button-wrapper,
  .btn-failure .mat-button-wrapper {
    color: #FFFFFF;
    font-size: 16px; }

.btn-delete {
  background-color: #ed1c24; }
  .btn-delete:hover, .btn-delete:focus {
    background-color: #ef343b; }
  .btn-delete:active {
    background-color: #de1219; }
  .btn-delete:disabled {
    background: #ed1c24; }
  .btn-delete.loading {
    background-color: #ed1c24;
    opacity: 1; }
  .btn-delete .md-button-wrapper,
  .btn-delete .mat-button-wrapper {
    color: #FFFFFF;
    font-size: 16px; }

.btn-inverted-delete, .btn-primary-inverted, .btn-default {
  background-color: #f8fafc;
  color: #ed1c24; }
  .btn-inverted-delete:hover, .btn-inverted-delete:focus, .btn-primary-inverted:hover, .btn-primary-inverted:focus, .btn-default:hover, .btn-default:focus {
    background-color: white; }
  .btn-inverted-delete:active, .btn-primary-inverted:active, .btn-default:active {
    background-color: #e6edf4; }
  .btn-inverted-delete:disabled, .btn-primary-inverted:disabled, .btn-default:disabled {
    background: #f8fafc; }
  .btn-inverted-delete.loading, .btn-primary-inverted.loading, .btn-default.loading {
    background-color: #f8fafc;
    opacity: 1; }
  .btn-inverted-delete:before, .btn-primary-inverted:before, .btn-default:before {
    mix-blend-mode: exclusion; }
  .btn-inverted-delete .md-button-wrapper,
  .btn-inverted-delete .mat-button-wrapper, .btn-primary-inverted .md-button-wrapper,
  .btn-primary-inverted .mat-button-wrapper, .btn-default .md-button-wrapper,
  .btn-default .mat-button-wrapper {
    color: #ed1c24;
    font-size: 16px; }

.btn-default {
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  color: #354052; }
  .btn-default .md-button-wrapper,
  .btn-default .mat-button-wrapper {
    color: #354052;
    font-size: 16px; }
  .btn-default.active {
    color: #2a81d7; }
    .btn-default.active .md-button-wrapper,
    .btn-default.active .mat-button-wrapper {
      color: #2a81d7; }

.btn-primary-inverted {
  background-color: #f8fafc;
  color: #354052; }
  .btn-primary-inverted:hover, .btn-primary-inverted:focus {
    background-color: white; }
  .btn-primary-inverted:active {
    background-color: #e6edf4; }
  .btn-primary-inverted:disabled {
    background: #f8fafc; }
  .btn-primary-inverted.loading {
    background-color: #f8fafc;
    opacity: 1; }
  .btn-primary-inverted .md-button-wrapper,
  .btn-primary-inverted .mat-button-wrapper {
    color: #354052;
    font-size: 16px; }

.btn-light {
  background-color: #f8fafc;
  color: #7f8fa4; }
  .btn-light:hover, .btn-light:focus {
    background-color: white; }
  .btn-light:active {
    background-color: #e6edf4; }
  .btn-light:disabled {
    background: #f8fafc; }
  .btn-light.loading {
    background-color: #f8fafc;
    opacity: 1; }
  .btn-light .md-button-wrapper,
  .btn-light .mat-button-wrapper {
    color: #7f8fa4;
    font-size: 16px; }

* {
  font-family: 'Source Sans Pro', sans-serif; }

h3 {
  color: #333333;
  font-size: 22px;
  font-weight: 200; }

.note {
  color: #72839B;
  font-size: 14px; }

input[type='text']:focus,
textarea:focus {
  outline-width: 0; }

input,
select {
  width: 100%;
  height: 36px;
  background-color: #ffffff;
  font-size: 14px;
  padding: 0 1em;
  border-radius: 4px;
  border: solid 1px #dfe3e9;
  font-weight: 400;
  line-height: 1.36;
  color: #354052; }

select:disabled,
input:disabled,
textarea:disabled {
  background: #eaeef1;
  border: 1px solid #dfe3e9; }

.input-group > input:disabled {
  background: #eaeef1;
  border: 0; }

[type='checkbox'],
[type='radio'] {
  width: auto;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  width: 16px;
  height: 16px;
  margin-top: -1px; }
  [type='checkbox']:after,
  [type='radio']:after {
    content: attr(data-label);
    color: #7f8fa4;
    vertical-align: top;
    margin-top: 0px;
    display: inline-block;
    margin-left: 2em;
    white-space: nowrap; }
  [type='checkbox']:checked:after,
  [type='radio']:checked:after {
    font-weight: 600;
    color: #354052; }

input[type='checkbox']:disabled, input[type='checkbox']:read-only,
input[type='radio']:disabled,
input[type='radio']:read-only {
  background-color: #eaeef1;
  border: solid 1px #dfe3e9; }
  input[type='checkbox']:disabled:after, input[type='checkbox']:read-only:after,
  input[type='radio']:disabled:after,
  input[type='radio']:read-only:after {
    opacity: 0.5; }

label[data-label] {
  display: inline-block;
  margin-bottom: 0; }
  label[data-label]:after {
    content: attr(data-label);
    color: #7f8fa4;
    font-weight: normal;
    vertical-align: top;
    margin-top: 0px;
    display: inline-block;
    white-space: nowrap; }

input[type='checkbox']:checked + label[data-label]:after,
input[type='radio']:checked + label[data-label]:after {
  font-weight: 600;
  color: #354052; }

[type='checkbox'] {
  border-radius: 4px;
  background-image: linear-gradient(to top, #f2f4f8, #feffff);
  border: solid 1px #ced0da; }
  [type='checkbox']:checked {
    background: url(/assets/img/icn-checkmark.svg) 3px 4px no-repeat #00c853;
    border: solid 1px rgba(0, 0, 0, 0.15); }

[type='radio'] {
  border-radius: 10px;
  background-image: linear-gradient(to top, #f2f4f8, #feffff);
  border: solid 1px #ced0da;
  position: relative;
  cursor: pointer; }
  [type='radio']:checked {
    width: 16px;
    height: 16px;
    background: #00c853;
    border: solid 1px rgba(0, 0, 0, 0.15); }
    [type='radio']:checked:before {
      width: 6px;
      height: 6px;
      background-color: #ffffff;
      content: '';
      display: inline-block;
      border-radius: 50%;
      padding: 0;
      position: absolute;
      top: 4px;
      left: 4px; }

.toggle {
  width: 38px;
  height: 20px;
  border-radius: 83px;
  background: #dfe1e5;
  transition: background 0.5s;
  position: relative;
  cursor: pointer; }
  .toggle:before {
    width: 16px;
    height: 16px;
    border-radius: 83px;
    background-color: #fff;
    transition: left 0.5s;
    content: '';
    display: inline-block;
    top: 1px;
    left: 1px;
    position: absolute; }
  .toggle:after {
    margin-left: 3.6em; }
  .toggle:checked {
    background: #00c853; }
    .toggle:checked:before {
      left: 19px; }
    .toggle:checked:after {
      font-weight: 600; }

input[type='checkbox'].toggle:disabled, input[type='checkbox'].toggle:read-only {
  background: #eaeef1;
  cursor: default; }

label {
  display: block;
  margin-bottom: 5px;
  color: #7f8fa4;
  font-weight: 600; }
  label.input-field-toggle {
    cursor: pointer;
    color: #2a81d7; }

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  border: 1px solid #ced0da;
  padding: 0 14px;
  font-weight: 600;
  color: #354052; }
  select:focus {
    outline: none;
    border-color: #2a81d7; }
  select:invalid {
    color: rgba(53, 64, 82, 0.5); }

input {
  border: solid 1px #dfe3e9; }
  input:focus {
    outline: none;
    border: 1px solid #2a81d7; }

my-date-picker .mydp {
  border: none; }
  my-date-picker .mydp .selectiongroup {
    height: 34px;
    background: linear-gradient(to top, #f2f4f7, #ffffff);
    background-color: transparent;
    border: solid 1px #ced0da; }
  my-date-picker .mydp .btnclear:focus,
  my-date-picker .mydp .btndecrease:focus,
  my-date-picker .mydp .btnincrease:focus,
  my-date-picker .mydp .btnpicker:focus,
  my-date-picker .mydp .headertodaybtn:focus {
    background: transparent; }
  my-date-picker .mydp .btndecrease,
  my-date-picker .mydp .btnincrease {
    background-color: transparent;
    border-right: 0;
    border-left: 0; }
    my-date-picker .mydp .btndecrease:hover,
    my-date-picker .mydp .btnincrease:hover {
      background-color: transparent; }
  my-date-picker .mydp .selectiondisabled {
    background: none; }
  my-date-picker .mydp .selectiongroup {
    width: 100%; }
  my-date-picker .mydp .selection {
    background: url(/assets/img/icons/icn-calendar.svg);
    background-position: 12px 5px, 0 0;
    background-repeat: no-repeat;
    padding-left: 40px;
    font-weight: 600;
    color: #354052;
    padding-bottom: 2px;
    background-color: transparent;
    font-family: 'Source Sans Pro', sans-serif; }
  my-date-picker .mydp .btnpicker {
    background-color: transparent;
    border: 0; }
    my-date-picker .mydp .btnpicker:hover {
      background-color: transparent; }
  my-date-picker .mydp .selbtngroup {
    width: 28px; }
  my-date-picker .mydp .selbtngroup .mydpicon {
    position: absolute;
    right: 0; }
    my-date-picker .mydp .selbtngroup .mydpicon:before {
      position: absolute;
      color: #a8aab7;
      font-size: 12px;
      transform: scale(2.2) translateY(2px); }
  my-date-picker .mydp .header .icon-mydpright {
    transform: rotate(-90deg);
    margin-top: 5px; }
    my-date-picker .mydp .header .icon-mydpright:before {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\e904'; }
  my-date-picker .mydp .header .icon-mydpleft {
    transform: rotate(90deg); }
    my-date-picker .mydp .header .icon-mydpleft:before {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\e904';
      transform: scale(2.5) translateY(2px); }
  my-date-picker .mydp .headertodaybtn .mydpicon,
  my-date-picker .mydp .btnpicker {
    display: none; }
  my-date-picker .mydp .btndecrease .icon-mydpleft:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\e91e';
    top: -12px;
    right: 4px;
    font-size: 14px; }
  my-date-picker .mydp .btnincrease .icon-mydpright:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\e91f';
    top: -12px;
    right: 6px;
    font-size: 14px; }

my-date-picker.date-select {
  width: 164px;
  height: 34px;
  margin-top: 0px; }
  my-date-picker.date-select .mydp {
    height: 34px; }
    my-date-picker.date-select .mydp .selection {
      background: url(/assets/img/icons/icn-calendar.svg);
      background-position: -4px 5px, 0 0;
      background-repeat: no-repeat;
      padding-left: 24px; }

my-date-picker.fixed .mydp .selector {
  position: fixed; }

.checkin .mydp .selector {
  right: 0; }

.checkin .pin-left .mydp .selector {
  left: 0;
  right: unset; }

textarea {
  width: 100%;
  height: 150px;
  border-radius: 4px;
  padding: 0.5em 1em;
  background-color: #ffffff;
  border: solid 1px #dfe3e9;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.36;
  color: #354052; }

form.ng-invalid,
form.ng-pristine.ng-invalid {
  border: 0;
  background: none; }
  form.ng-invalid:focus,
  form.ng-pristine.ng-invalid:focus {
    outline: none;
    border: 1px solid #2a81d7; }

input.ng-invalid.ng-touched {
  border-radius: 4px;
  border: solid 1px #ed1c24;
  color: #ed1c24; }

input.ng-pristine.ng-invalid {
  border-radius: 4px;
  border: solid 1px #dfe3e9;
  color: #354052; }
  input.ng-pristine.ng-invalid:focus {
    outline: none;
    border: 1px solid #2a81d7; }

.required:after {
  content: ' *';
  color: red; }

form .label.required:after,
form label.required:after {
  content: ' *'; }

form .toggle-label {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
  padding-left: 38px; }

form .toggle-label .toggle-input {
  display: none; }

form .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #ccc;
  -ms-transition: 0.5s;
  transition: 0.5s; }

form .slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  top: 2px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -ms-transition: 0.5s;
  transition: 0.5s; }

form .toggle-input:checked + .slider {
  background-color: #00c853; }

form .toggle-input:focus + .slider {
  box-shadow: 0 0 1px #00c853; }

form .toggle-input:checked + .slider:before {
  left: 16px; }

form .toggle-input:checked + .toggle-label:after {
  font-weight: 600; }

form .slider.round {
  border-radius: 83px;
  right: 4px; }

form .slider.round:before {
  border-radius: 50%; }

.field-group {
  position: relative;
  margin-bottom: 10px; }
  .field-group.state:after {
    pointer-events: none;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\E904';
    position: absolute;
    top: 32px;
    right: 8px;
    color: #a8aab7;
    font-size: 12px;
    transform: scale(2) translateY(2px); }

.input-icon {
  position: absolute;
  height: 0;
  right: 8px;
  bottom: 28px;
  text-align: right;
  font-family: FontAwesome; }
  .input-icon.ng-invalid {
    margin-left: -28px; }

.input-icon.ng-valid:before {
  color: #00c853;
  content: '\f00c'; }

.input-icon.ng-invalid:before {
  color: #ed1c24;
  content: '\f00d'; }

.input-icon.ng-pristine:before {
  content: ''; }

.input-pair {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: solid 1px #ced0da;
  overflow: visible;
  background: #fff; }
  .input-pair > * {
    flex: 1 1 50%;
    border: none;
    border-right: solid 1px #ced0da;
    border-radius: 0 !important; }
    .input-pair > *:first-child {
      border-right: solid 1px #ced0da; }
  .input-pair > input {
    flex: 1 1 auto;
    background: transparent; }
  .input-pair dropdown:first-child .dropdown {
    width: auto; }
    .input-pair dropdown:first-child .dropdown .dropdown-button {
      border: none; }
      .input-pair dropdown:first-child .dropdown .dropdown-button:after {
        margin-left: 1em; }
  .input-pair .input-group {
    border: none; }

.input-group {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: solid 1px #ced0da;
  overflow: visible;
  background: #fff; }
  .input-group[data-prefix] {
    position: relative; }
    .input-group[data-prefix]:before {
      content: attr(data-prefix);
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      color: #7f8fa4;
      position: absolute;
      top: 9px;
      left: 9px; }
    .input-group[data-prefix] > :first-child {
      padding-left: 18px; }
  .input-group[data-suffix] {
    position: relative; }
    .input-group[data-suffix]:after {
      content: attr(data-suffix);
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      color: #7f8fa4;
      position: absolute;
      top: 9px;
      right: 9px; }
    .input-group[data-suffix] > :last-child {
      padding-right: 18px; }
  .input-group > * {
    flex: 0 0 auto;
    border: none;
    border-right: solid 1px #ced0da;
    border-radius: 0 !important; }
    .input-group > *:last-child {
      border-right: none; }
  .input-group > input {
    flex: 1 1 auto;
    background: transparent; }
  .input-group dropdown:last-child .dropdown {
    width: auto; }
    .input-group dropdown:last-child .dropdown .dropdown-button {
      border: none; }
      .input-group dropdown:last-child .dropdown .dropdown-button:after {
        margin-left: 1em; }
  .input-group .add-on:last-child {
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #354052; }

.edit-drawer {
  overflow-y: scroll; }
  .edit-drawer .field-group.state:after {
    right: 38px; }

.timepicker-wrapper {
  background: linear-gradient(to top, #f2f4f7, #ffffff);
  background-color: transparent;
  border: solid 1px #ced0da;
  border-radius: 4px;
  height: 36px; }

.ui-timepicker-wrapper {
  font-weight: 600;
  z-index: 99999 !important; }

.timepicker {
  background: url(/assets/img/icons/icn-time.svg);
  background-position: 6px center, 0 0;
  background-repeat: no-repeat;
  border: none;
  padding-left: 44px;
  font-weight: 600;
  color: #354052;
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif; }
  .timepicker:focus {
    border: none; }

.segmented-control {
  display: flex;
  width: 100%;
  padding: 0;
  height: 36px;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff); }
  .segmented-control .item:first-child .label {
    border-radius: 4px 0px 0px 4px; }
  .segmented-control .item:last-child .label {
    border-radius: 0px 4px 4px 0px; }
  .segmented-control .item {
    display: flex;
    flex: 1;
    margin: 0;
    padding: 0;
    list-style-type: none; }
  .segmented-control .input {
    position: absolute;
    visibility: hidden; }
  .segmented-control .label {
    display: block;
    width: 100%;
    margin: 0 -1px -1px 0;
    padding: 8px 28px 8px 14px;
    border: solid 1px #ced0da;
    text-align: center;
    cursor: pointer; }
  .segmented-control .label:hover {
    background: #fafafa; }
  .segmented-control .input:checked + .label {
    background: #eee;
    color: #333; }

.percentage-mask {
  position: relative; }
  .percentage-mask:after {
    position: absolute;
    top: 0;
    right: 10px;
    content: '%';
    font-weight: 600;
    color: #7f8fa4; }

@media screen and (max-width: 900px) {
  .ui-timepicker-wrapper {
    position: fixed;
    top: 44px;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    box-shadow: none;
    border: none; }
    .ui-timepicker-wrapper ul li {
      padding: 12px 15px;
      border-bottom: solid 1px #e7e9ed; } }

.flex-field-group {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: space-around;
  justify-content: space-around;
  margin-top: 25px; }
  .flex-field-group div:first-child {
    margin-right: 25px; }
  .flex-field-group div:last-child {
    margin-right: 0; }
  .flex-field-group .flex-field {
    box-sizing: border-box;
    width: calc(1/2 * 100% - (1 - 1/2) * 10px); }
    .flex-field-group .flex-field label {
      text-transform: uppercase;
      font-size: 12px; }
    .flex-field-group .flex-field input:disabled {
      background-color: white;
      color: #aaa;
      border-bottom: 1px solid #eee; }
    .flex-field-group .flex-field__fill {
      width: 100%; }
    .flex-field-group .flex-field__disabled {
      color: gray; }
    .flex-field-group .flex-field:last-child .mat-form-field-flex {
      margin-right: 0px; }

.md-dialog-container,
.mat-dialog-container {
  padding: 0 !important;
  overflow: visible !important;
  border-radius: 4px !important;
  height: auto !important;
  min-height: 100%; }
  .md-dialog-container .dropdown,
  .mat-dialog-container .dropdown {
    width: 100%; }

md-dialog-container,
.md-dialog-container,
mat-dialog-container,
.mat-dialog-container {
  max-width: 100VW; }

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.90; }

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.9); }

md-dialog-container [mat-dialog-content],
md-dialog-container [md-dialog-content],
md-dialog-container mat-dialog-content,
md-dialog-container md-dialog-content,
mat-dialog-container [mat-dialog-content],
mat-dialog-container [md-dialog-content],
mat-dialog-container mat-dialog-content,
mat-dialog-container md-dialog-content {
  display: block;
  padding: 0 30px;
  overflow: visible;
  margin: 0;
  flex: 1 0 auto;
  padding-bottom: 20px;
  max-height: none; }

md-dialog-container p,
mat-dialog-container p {
  text-align: left;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  color: #72839b;
  margin-top: 30px;
  margin-bottom: 40px; }

md-dialog-container .alert-success h2,
mat-dialog-container .alert-success h2 {
  text-align: center;
  color: #333;
  font-size: 22px;
  font-weight: 300;
  margin: 17px 0 0; }

md-dialog-container .modal-title,
mat-dialog-container .modal-title {
  margin: 0;
  background-color: #2a81d7;
  min-height: 60px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 4px 4px 0 0; }
  md-dialog-container .modal-title h1,
  mat-dialog-container .modal-title h1 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    color: #ffffff; }
  md-dialog-container .modal-title .close-action,
  mat-dialog-container .modal-title .close-action {
    position: absolute;
    top: 16px;
    right: 22px;
    color: rgba(255, 255, 255, 0.4);
    font-size: 28px; }
    md-dialog-container .modal-title .close-action:hover,
    mat-dialog-container .modal-title .close-action:hover {
      color: white; }

md-dialog-container .modal-footer,
mat-dialog-container .modal-footer {
  min-height: 60px;
  background-color: #f0f3f8;
  padding: 12px 30px;
  text-align: center;
  display: flex;
  border-radius: 0 0 4px 4px; }
  md-dialog-container .modal-footer .btn, md-dialog-container .modal-footer ruckit-button,
  mat-dialog-container .modal-footer .btn,
  mat-dialog-container .modal-footer ruckit-button {
    flex: 1 1 0;
    margin: 0 0 0 20px; }
    md-dialog-container .modal-footer .btn:first-child, md-dialog-container .modal-footer ruckit-button:first-child,
    mat-dialog-container .modal-footer .btn:first-child,
    mat-dialog-container .modal-footer ruckit-button:first-child {
      margin: 0; }

.cdk-global-overlay-wrapper {
  overflow: auto; }

@media only screen and (min-device-width: 300px) and (max-device-width: 900px) {
  md-dialog-container,
  .md-dialog-container,
  mat-dialog-container,
  .mat-dialog-container {
    width: 100%;
    height: 100%;
    max-width: 100VW;
    border-radius: 0;
    overflow: scroll; }
    md-dialog-container .modal-title,
    .md-dialog-container .modal-title,
    mat-dialog-container .modal-title,
    .mat-dialog-container .modal-title {
      border-radius: 0; }
    md-dialog-container form,
    .md-dialog-container form,
    mat-dialog-container form,
    .mat-dialog-container form {
      height: 100VH !important; }
    md-dialog-container md-dialog-content,
    md-dialog-container mat-dialog-content,
    .md-dialog-container md-dialog-content,
    .md-dialog-container mat-dialog-content,
    mat-dialog-container md-dialog-content,
    mat-dialog-container mat-dialog-content,
    .mat-dialog-container md-dialog-content,
    .mat-dialog-container mat-dialog-content {
      overflow: scroll;
      max-height: calc(100VH - 120px); }
    md-dialog-container confirm-drivers-dialog form,
    .md-dialog-container confirm-drivers-dialog form,
    mat-dialog-container confirm-drivers-dialog form,
    .mat-dialog-container confirm-drivers-dialog form {
      height: 100% !important; } }

.list-row {
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #e6eaee;
  display: flex;
  flex-wrap: nowrap;
  margin: 10px 30px 0px 30px;
  padding: 20px 30px 20px 30px; }

h2.value {
  margin: 0; }

table {
  text-align: left;
  border-collapse: separate;
  border: 1px solid #dfe2e5;
  border-bottom: 0px;
  border-spacing: 0px;
  border-radius: 4px;
  -moz-border-radius: 4px; }
  table.full-width {
    width: 100%; }
  table tr td {
    border-top: 1px solid #dfe2e5;
    padding: 15px 9px; }
  table tr .value {
    font-size: 14px;
    font-weight: 600;
    color: #354052; }
    table tr .valueh2 {
      font-size: 16px; }
  table tr .label {
    font-size: 12px;
    color: #7f8fa4; }

thead tr {
  border-radius: 3px;
  background-color: #f5f8fa;
  height: 36px; }
  thead tr th {
    font-size: 14px;
    font-weight: 400;
    color: #7f8fa4;
    padding: 9px;
    border: none; }

.list-table .row.selected,
.list-table .row:hover {
  background: #f8fafc; }

.list-table .row * > a {
  text-decoration: none;
  color: #58b4f9; }

.list-table .load-signature img,
.list-table .load-signature-image img,
.list-table .unload-signature img,
.list-table .unload-signature-image img {
  background: #ffffff; }

@media screen and (max-width: 900px) {
  .mobile-list {
    position: absolute;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: #ffffff;
    width: 100%;
    top: 140px;
    bottom: 0;
    margin-bottom: 44px;
    padding-bottom: 160px; }
    .mobile-list .slot {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 84px;
      margin-bottom: 0;
      padding: 15px;
      border: none;
      border-bottom: solid 1px #e7e9ed;
      border-radius: none; }
      .mobile-list .slot .icon, .mobile-list .slot .info, .mobile-list .slot .actions {
        display: flex;
        justify-content: center; }
      .mobile-list .slot .icon {
        width: 50px;
        height: 50px;
        margin-right: 15px;
        border-radius: 50%;
        background-color: #2a81d7;
        overflow: hidden;
        color: white;
        align-items: center; }
        .mobile-list .slot .icon span {
          font-size: 24px;
          margin-top: -2px; }
        .mobile-list .slot .icon img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          object-position: center; }
      .mobile-list .slot .info, .mobile-list .slot .actions {
        flex-direction: column; }
        .mobile-list .slot .info h3, .mobile-list .slot .info h4, .mobile-list .slot .info h5, .mobile-list .slot .actions h3, .mobile-list .slot .actions h4, .mobile-list .slot .actions h5 {
          margin: 0; }
        .mobile-list .slot .info h3, .mobile-list .slot .actions h3 {
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 6px; }
        .mobile-list .slot .info h4, .mobile-list .slot .info h5, .mobile-list .slot .actions h4, .mobile-list .slot .actions h5 {
          font-size: 13px;
          font-weight: 500;
          color: #b1b3b5; }
        .mobile-list .slot .info h5, .mobile-list .slot .actions h5 {
          font-style: italic; }
      .mobile-list .slot .actions {
        cursor: pointer;
        justify-self: flex-end;
        align-self: center;
        margin-left: auto;
        font-size: 40px;
        text-align: right;
        color: #b2b2b2; }
        .mobile-list .slot .actions .icon-delete {
          font-size: 28px;
          margin-right: 12px; }
        .mobile-list .slot .actions .radio-button {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: #e6e6e6;
          border: none;
          margin: 0 15px 20px 0;
          padding: 0; }
          .mobile-list .slot .actions .radio-button.active {
            border: #00c853 7px solid;
            background-color: white; }
          .mobile-list .slot .actions .radio-button:focus, .mobile-list .slot .actions .radio-button:active {
            outline: none; }
      .mobile-list .slot.empty .icon {
        background-color: #00c853;
        font-size: 34px; }
      .mobile-list .slot.empty > div {
        pointer-events: auto; }
      .mobile-list .slot.filled {
        cursor: default; }
  .bottom-action {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 86px;
    padding: 15px 25px 25px;
    border-top: 1px solid #f2f2f2;
    background-color: white; }
    .bottom-action button {
      width: 100%;
      height: 100%;
      color: white;
      background-color: #00c853;
      border: none;
      border-radius: 3px;
      font-size: 14px;
      cursor: pointer; }
      .bottom-action button:focus, .bottom-action button:active {
        outline: none; } }

.label-success {
  height: 20px;
  border-radius: 3px;
  background-color: #00c853;
  color: #fff;
  display: inline-block;
  margin-right: 1em;
  padding: 4px 10px;
  vertical-align: middle;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap; }

.label-info,
.label-working,
.label-accepted,
.label-paid {
  height: 20px;
  border-radius: 3px;
  background-color: #2a81d7;
  color: #fff;
  display: inline-block;
  margin-right: 1em;
  padding: 4px 10px;
  vertical-align: middle;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap; }

.label-danger,
.label-rejected,
.label-declined {
  height: 20px;
  border-radius: 3px;
  background-color: #ed1c24;
  color: #fff;
  display: inline-block;
  margin-right: 1em;
  padding: 4px 10px;
  vertical-align: middle;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap; }

.label-secondary {
  height: 20px;
  border-radius: 3px;
  background-color: #516173;
  color: #fff;
  display: inline-block;
  margin-right: 1em;
  padding: 4px 10px;
  vertical-align: middle;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap; }

.label-warn,
.label-waiting,
.label-pending {
  height: 20px;
  border-radius: 3px;
  background-color: #f7981c;
  color: #fff;
  display: inline-block;
  margin-right: 1em;
  padding: 4px 10px;
  vertical-align: middle;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap; }

.label-disabled {
  height: 20px;
  border-radius: 3px;
  background-color: #dfe1e5;
  color: #fff;
  display: inline-block;
  margin-right: 1em;
  padding: 4px 10px;
  vertical-align: middle;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  color: #354052; }

.label-expired,
.label-cancelled,
.label-upcoming {
  height: 20px;
  border-radius: 3px;
  background-color: #c7d2e0;
  color: #fff;
  display: inline-block;
  margin-right: 1em;
  padding: 4px 10px;
  vertical-align: middle;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  color: #ffffff; }

.yet-another-label-color {
  height: 20px;
  border-radius: 3px;
  background-color: #dfe1e5;
  color: #fff;
  display: inline-block;
  margin-right: 1em;
  padding: 4px 10px;
  vertical-align: middle;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap; }

.label-edited,
.label-expensed {
  height: 20px;
  border-radius: 3px;
  background-color: #0078d9;
  color: #fff;
  display: inline-block;
  margin-right: 1em;
  padding: 4px 10px;
  vertical-align: middle;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap; }

.label-void {
  height: 20px;
  border-radius: 3px;
  background-color: #ed1c24;
  color: #fff;
  display: inline-block;
  margin-right: 1em;
  padding: 4px 10px;
  vertical-align: middle;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap; }

.label-retake {
  height: 20px;
  border-radius: 3px;
  background-color: #f7981c;
  color: #fff;
  display: inline-block;
  margin-right: 1em;
  padding: 4px 10px;
  vertical-align: middle;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap; }

.label-finalized {
  height: 20px;
  border-radius: 3px;
  background-color: #00c853;
  color: #fff;
  display: inline-block;
  margin-right: 1em;
  padding: 4px 10px;
  vertical-align: middle;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap; }

.alert {
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  padding: 9px;
  margin-bottom: 0.5em;
  transition: all 0.5s;
  animation-duration: 0.5s;
  animation-name: bounceIn; }
  .alert:before {
    content: '\f05a';
    font-family: FontAwesome;
    color: #FFFFFF;
    opacity: 0.7;
    padding-left: 2px;
    padding-right: 12px;
    transform: scale(1.25); }
  .alert.alert-warn {
    background-color: #f7981c; }
  .alert.alert-success {
    background-color: #00c853; }
  .alert.alert-danger {
    background-color: #ed1c24; }
  .alert.alert-info {
    background-color: #7f8fa4; }
  .alert.alert-danger:before {
    content: '\f057'; }
  .alert.fade {
    opacity: 0; }
  .alert.slide {
    font-size: 0;
    padding: 0;
    opacity: 0; }
  .alert .close {
    transform: rotate(-315deg) translate(-6px, -5px);
    opacity: 0.4;
    float: right;
    font-size: 27px;
    font-weight: 500;
    cursor: pointer; }

@keyframes bounceIn {
  0% {
    transform: scale(0.8);
    opacity: 0; }
  60% {
    transform: scale(1.1);
    opacity: 1; }
  100% {
    transform: scale(1); } }

@media screen and (max-width: 900px) {
  notification {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000; }
  .alert {
    border-radius: 0;
    padding: 13px; } }

mat-form-field {
  width: 100%;
  max-width: 500px; }
  mat-form-field mat-chip-list .mat-chip-list-wrapper {
    padding: 0 3px 3px;
    height: auto;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #dfe3e9; }
    mat-form-field mat-chip-list .mat-chip-list-wrapper input {
      margin: 3px 0 0;
      padding: 5px 7px 5px 12px;
      height: 28px;
      border-radius: 3px;
      color: #333c48;
      font-size: 14px;
      font-weight: 600;
      flex: 0 0 0; }
      mat-form-field mat-chip-list .mat-chip-list-wrapper input.mat-chip-input {
        width: auto; }
      mat-form-field mat-chip-list .mat-chip-list-wrapper input:focus {
        border: none;
        width: auto; }
      mat-form-field mat-chip-list .mat-chip-list-wrapper input::after {
        content: "&plus;"; }
  mat-form-field mat-chip-list mat-chip, mat-form-field mat-chip-list .mat-chip:not(.mat-basic-chip), mat-form-field mat-chip-list .mat-chip:not(.mat-basic-chip) + .mat-chip:not(.mat-basic-chip) {
    height: 28px;
    border-radius: 3px;
    background-color: #f1f4f8;
    border: solid 1px #d5dce6;
    color: #333c48;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 7px 5px 12px;
    margin: 3px 3px 0 0; }
  mat-form-field .mat-input-wrapper {
    padding-bottom: 0; }

.mat-form-field-infix {
  padding: 0;
  border-top: none; }

.mat-form-field-underline, .mat-input-underline {
  display: none;
  visibility: none; }

.mat-autocomplete-panel {
  transition: height 0.3s ease-in, box-shadow 0.3s;
  box-shadow: none;
  border-radius: 4px;
  overflow: hidden; }
  .mat-autocomplete-panel::-webkit-scrollbar, .mat-autocomplete-panel::-webkit-scrollbar-track {
    border-radius: 0 0 4px 0; }
  .mat-autocomplete-panel::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1); }
  .mat-autocomplete-panel::-webkit-scrollbar-thumb {
    border: 2px solid #fff;
    background-color: #aaa;
    border-radius: 10px; }
  .mat-autocomplete-panel::-webkit-scrollbar-thumb:window-inactive {
    background: #eee;
    all: unset; }
  .mat-autocomplete-panel.mat-autocomplete-visible {
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.06); }
  .mat-autocomplete-panel mat-option {
    box-shadow: inset 0 -1px 0 0 #dfe3e9;
    font-size: 14px;
    font-weight: 600;
    color: #354052;
    padding: 0px 40px 0px 14px;
    white-space: nowrap;
    min-height: 37px;
    line-height: 37px;
    height: auto; }
    .mat-autocomplete-panel mat-option:hover:not(.mat-option-disabled) {
      background: none;
      color: #2a81d7; }

* * {
  box-sizing: border-box; }

.dropdown {
  position: relative;
  font-size: 14px;
  cursor: pointer; }
  .dropdown.open {
    z-index: 9999; }
    .dropdown.open .dropdown-menu {
      box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.06);
      display: block; }
      .dropdown.open .dropdown-menu .dropdown-options .scrollable {
        overflow-y: auto; }
  .dropdown.disabled .dropdown-button {
    background: #eaeef1;
    border: solid 1px #dfe3e9;
    color: rgba(53, 64, 82, 0.5); }
  .dropdown.disabled .dropdown-menu {
    display: none; }
  .dropdown.empty .dropdown-button {
    color: rgba(53, 64, 82, 0.5); }
  .dropdown.action, .dropdown.user {
    margin-right: 0;
    display: inline-block;
    width: auto; }
    .dropdown.action.empty .dropdown-button, .dropdown.user.empty .dropdown-button {
      color: #7f8fa4; }
    .dropdown.action.open .dropdown-button, .dropdown.user.open .dropdown-button {
      color: #354052; }
    .dropdown.action:focus .dropdown-button, .dropdown.user:focus .dropdown-button {
      color: #2a81d7; }
    .dropdown.action .dropdown-button, .dropdown.user .dropdown-button {
      display: inline-block;
      background: none;
      border: none;
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 0;
      padding: 0px 27px 8px 0;
      width: auto; }
      .dropdown.action .dropdown-button:hover, .dropdown.user .dropdown-button:hover {
        color: #2a81d7; }
      .dropdown.action .dropdown-button:after, .dropdown.user .dropdown-button:after {
        float: none;
        margin-left: 1em;
        color: inherit; }
    .dropdown.action .dropdown-menu, .dropdown.user .dropdown-menu {
      width: 243px; }
      .dropdown.action .dropdown-menu .dropdown-options, .dropdown.user .dropdown-menu .dropdown-options {
        width: auto; }
    .dropdown.action.user .dropdown-button, .dropdown.user.user .dropdown-button {
      color: #fff;
      font-size: 12px;
      height: 46px;
      margin-top: 10px; }
      .dropdown.action.user .dropdown-button:after, .dropdown.user.user .dropdown-button:after {
        margin-left: 10px;
        opacity: 0.5;
        position: relative;
        top: 1px; }
    .dropdown.action.user .dropdown-menu, .dropdown.user.user .dropdown-menu {
      top: 55px;
      right: 0;
      left: auto;
      width: auto; }
  .dropdown.searchable .dropdown-options {
    height: 230px; }
  .dropdown.action .dropdown-menu {
    top: 37px; }
  .dropdown.action.bottom-right .dropdown-menu {
    right: 50%;
    top: -50%; }
  .dropdown.right-align .dropdown-menu {
    right: 0; }
  .dropdown .dropdown-button {
    white-space: nowrap;
    overflow: hidden;
    min-width: 100%;
    height: 36px;
    line-height: 34px;
    vertical-align: middle;
    border-radius: 4px;
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    border: solid 1px #ced0da;
    font-weight: 600;
    color: #354052;
    padding: 0px 28px 8px 14px;
    margin-bottom: 0px;
    text-overflow: ellipsis; }
    .dropdown .dropdown-button:focus {
      outline: none;
      border-color: #2a81d7; }
    .dropdown .dropdown-button:after {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e904";
      position: absolute;
      top: 9px;
      right: 9px;
      color: #a8aab7;
      font-size: 12px;
      transform: scale(2) translateY(2px); }
  .dropdown .dropdown-button.custom-icon {
    font-size: 24px;
    text-align: right; }
    .dropdown .dropdown-button.custom-icon i {
      color: #354052; }
  .dropdown .dropdown-button.custom-icon:after {
    content: ''; }
  .dropdown .dropdown-menu {
    position: absolute;
    height: 0;
    top: 41px;
    transition: height 0.3s ease-in, box-shadow 0.3s;
    box-shadow: none;
    border-radius: 4px;
    width: 100%;
    overflow: hidden;
    min-width: 100%;
    z-index: 9999; }
    .dropdown .dropdown-menu .dropdown-options {
      left: 0;
      bottom: 0;
      min-width: 100%;
      z-index: 9999;
      background: #fff;
      border-radius: 4px;
      max-height: calc(100VH);
      border: solid 1px #dfe3e9;
      display: flex;
      flex-direction: column;
      max-height: 230px; }
      .dropdown .dropdown-menu .dropdown-options .scrollable {
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
        -ms-overflow-style: none; }
        .dropdown .dropdown-menu .dropdown-options .scrollable::-webkit-scrollbar, .dropdown .dropdown-menu .dropdown-options .scrollable::-webkit-scrollbar-track {
          border-radius: 0 0 4px 0; }
        .dropdown .dropdown-menu .dropdown-options .scrollable::-webkit-scrollbar-track {
          box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1); }
        .dropdown .dropdown-menu .dropdown-options .scrollable::-webkit-scrollbar-thumb {
          border: 2px solid #fff;
          background-color: #aaa;
          border-radius: 10px; }
        .dropdown .dropdown-menu .dropdown-options .scrollable::-webkit-scrollbar-thumb:window-inactive {
          background: #eee;
          all: unset; }
    .dropdown .dropdown-menu .option {
      white-space: nowrap;
      min-height: 37px;
      line-height: 37px;
      vertical-align: middle;
      padding: 0px 40px 0px 14px;
      text-align: left;
      display: flex;
      flex-direction: row;
      color: #354052;
      font-weight: 600;
      box-shadow: inset 0 -1px 0 0 #dfe3e9;
      position: relative; }
      .dropdown .dropdown-menu .option.has-image {
        padding: 8px 40px 12px 14px; }
      .dropdown .dropdown-menu .option.disabled {
        background: #e0e0e0;
        opacity: 0.4;
        cursor: not-allowed; }
      .dropdown .dropdown-menu .option:focus, .dropdown .dropdown-menu .option:hover {
        outline: none;
        color: #2a81d7; }
      .dropdown .dropdown-menu .option.selected {
        background: #f1f4f8;
        color: #2a81d7; }
        .dropdown .dropdown-menu .option.selected:after {
          /* use !important to prevent issues with browser extensions that change fonts */
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\e901";
          position: absolute;
          top: 7px;
          right: 8px;
          font-size: 22px; }
      .dropdown .dropdown-menu .option .subtitle {
        width: 100%;
        display: block;
        font-size: 12px;
        color: #7f8fa4; }
      .dropdown .dropdown-menu .option .option.ng-star-inserted {
        float: left;
        position: relative; }
      .dropdown .dropdown-menu .option .option-right {
        line-height: 18px;
        flex: 0;
        flex-direction: column;
        display: flex; }
        .dropdown .dropdown-menu .option .option-right.no-subtitle {
          line-height: 37px; }
      .dropdown .dropdown-menu .option .image {
        width: 54px;
        height: 36px;
        border-radius: 2px;
        margin-right: 10px;
        display: block;
        float: left; }
      .dropdown .dropdown-menu .option .default-image {
        float: left;
        width: 54px;
        height: 36px;
        margin-right: 10px;
        overflow: hidden;
        background-size: cover;
        background-position: center center; }
        .dropdown .dropdown-menu .option .default-image img {
          border-radius: 2px;
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .dropdown .dropdown-menu .option .default-image i {
          line-height: 36px;
          font-size: 26px; }
    .dropdown .dropdown-menu .group-header {
      padding: 0px 40px 0px 14px;
      line-height: 36px;
      vertical-align: middle;
      color: #7f8fa4;
      font-weight: 600;
      box-shadow: inset 0 -1px 0 0 #dfe3e9; }
    .dropdown .dropdown-menu .group .option {
      padding-left: 24px; }
    .dropdown .dropdown-menu .group.empty .option {
      padding-left: 14px; }
    .dropdown .dropdown-menu .search-option {
      flex: 0 0 auto;
      padding: 4px;
      height: 44px; }
    .dropdown .dropdown-menu .loader {
      margin-top: 0.4em; }

.user-pic {
  height: 36px;
  width: 36px;
  display: inline-block;
  background: #00c853;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  margin-right: 1em;
  text-align: center; }
  .user-pic img {
    height: 100%;
    width: 100%; }
  .user-pic span {
    font-weight: 100;
    font-size: 25px;
    line-height: 35px; }

input.search {
  padding-right: 2em; }
  input.search + .icon-search {
    line-height: 37px;
    width: 1px;
    color: #ced0da;
    transform: scale(1.75) translateX(-1.1em);
    display: inline-block; }

.actions-dropdown .dropdown.empty .dropdown-button {
  color: #354052; }

.actions-dropdown .dropdown.disabled .dropdown-button {
  color: rgba(53, 64, 82, 0.5); }

.notification-menu.mat-menu-panel {
  margin-top: 30px;
  max-width: 100%;
  width: 400px;
  border-radius: 4px; }
  .notification-menu.mat-menu-panel .mat-menu-content, .notification-menu.mat-menu-panel .mat-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 360px;
    overflow-y: scroll; }
    .notification-menu.mat-menu-panel .mat-menu-content a, .notification-menu.mat-menu-panel .mat-menu-content:not(:empty) a {
      min-height: 62px;
      padding: 10px;
      height: auto;
      line-height: 17px;
      font-weight: 500; }
      .notification-menu.mat-menu-panel .mat-menu-content a:hover, .notification-menu.mat-menu-panel .mat-menu-content:not(:empty) a:hover {
        border-left: 4px solid #2a81d7;
        padding-left: 6px; }
      .notification-menu.mat-menu-panel .mat-menu-content a.read, .notification-menu.mat-menu-panel .mat-menu-content:not(:empty) a.read {
        background-color: #fafbfc; }
        .notification-menu.mat-menu-panel .mat-menu-content a.read > *, .notification-menu.mat-menu-panel .mat-menu-content:not(:empty) a.read > * {
          opacity: 0.5; }
        .notification-menu.mat-menu-panel .mat-menu-content a.read .icon, .notification-menu.mat-menu-panel .mat-menu-content:not(:empty) a.read .icon {
          background-color: #dfe3e9; }
          .notification-menu.mat-menu-panel .mat-menu-content a.read .icon > *, .notification-menu.mat-menu-panel .mat-menu-content:not(:empty) a.read .icon > * {
            color: #a1a2a9; }

.notification-menu .mat-menu-item {
  display: flex;
  white-space: normal;
  overflow: auto; }
  .notification-menu .mat-menu-item .text {
    padding-right: 10px;
    width: 100%; }
  .notification-menu .mat-menu-item .time {
    color: #7f8fa4; }

.notification-menu .icon {
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: #2a81d7; }
  .notification-menu .icon > * {
    color: white;
    font-size: 20px; }

.app .sidebar-container mobile-sidebar {
  display: none; }

.mobile-visible {
  display: none; }

@media (max-width: 900px) {
  .desktop-visible {
    display: none; }
  .mobile-visible {
    display: block; }
  div.app {
    flex-direction: column;
    height: 100vh; }
    div.app .sidebar-container {
      z-index: 9999; }
      div.app .sidebar-container my-sidebar {
        display: none; }
      div.app .sidebar-container mobile-sidebar {
        display: block;
        left: calc(-100vw + 88px);
        position: absolute;
        transition: transform 0.3s; }
    div.app .main-component-container {
      transition: transform 0.3s; }
    div.app.sidebar-open {
      overflow-x: hidden; }
      div.app.sidebar-open .sidebar-container mobile-sidebar {
        transform: translateX(calc(100vw - 88px)); }
      div.app.sidebar-open .main-component-container {
        transform: translateX(calc(100vw - 88px)); }
  .main-component-container {
    flex: 1 1 auto; }
    .main-component-container > :last-child {
      height: 100%; }
  .edit-drawer, .side-drawer {
    height: 100%;
    top: 0;
    right: 0;
    max-width: 100%; }
  action-bar mat-toolbar-row .action-bar-content {
    padding-left: 16px;
    padding-right: 16px; }
  .dropdown.user.user .dropdown-button {
    padding-right: 8px; }
  .action-bar-content {
    margin-top: -15px;
    padding: 0 15px; }
    .action-bar-content .subnav {
      margin-top: 20px; }
      .action-bar-content .subnav .links {
        justify-content: space-between; }
        .action-bar-content .subnav .links a {
          margin-right: 0;
          padding-bottom: 10px; }
          .action-bar-content .subnav .links a.active {
            padding-bottom: 10px; }
    .action-bar-content .left-actions {
      margin-top: 0; }
    .action-bar-content .right-actions {
      margin-top: 15px; }
      .action-bar-content .right-actions :first-child {
        margin-left: 0; }
  .grid .list-table .row {
    width: calc(100VW - 30px) !important; }
  mat-toolbar-row {
    height: 44px; }
  .cdk-overlay-container {
    z-index: 10000; } }

@media (max-width: 600px) and (orientation: portrait) {
  mat-toolbar-row {
    height: 44px !important; } }

.no-results {
  margin-top: 120px; }
  .no-results > * {
    width: 200px;
    text-align: center; }
  .no-results.mobile {
    margin-top: 40px; }
  .no-results .icon {
    color: #e2e3e9;
    font-size: 78px; }
  .no-results h2 {
    margin-top: 20px;
    margin-bottom: 0;
    color: #354052;
    font-size: 18px; }
  .no-results p {
    margin: 0;
    color: #7f8fa4;
    font-weight: 600; }
  .no-results .actions {
    margin-top: 25px; }
    .no-results .actions span {
      padding-bottom: 2px;
      margin-right: 4px;
      display: inline-block;
      vertical-align: middle; }

.action-bar-content {
  min-height: 54px;
  flex-wrap: wrap-reverse; }

action-bar md-toolbar,
action-bar mat-toolbar {
  margin-bottom: 20px;
  padding: 0;
  height: unset !important;
  min-height: unset !important; }

action-bar .mat-toolbar-single-row {
  height: unset !important;
  z-index: 1000;
  position: relative; }

action-bar .mat-toolbar {
  background: none;
  padding: 0; }

action-bar .mat-toolbar-row {
  height: unset; }

action-bar.transparent mat-toolbar {
  background: none; }
  action-bar.transparent mat-toolbar .action-bar-content {
    box-shadow: none; }

action-bar md-toolbar-row,
action-bar .mat-toolbar-single-row {
  height: auto; }

@media (max-width: 960px) {
  action-bar md-toolbar-row .action-bar-content,
  action-bar .mat-toolbar-single-row .action-bar-content {
    padding-bottom: 12px;
    padding-top: 12px; } }

@media (max-width: 500px) {
  action-bar md-toolbar,
  action-bar mat-toolbar {
    margin-bottom: 0; } }

.left-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 16px; }
  .left-actions h1.title {
    margin: 12px 30px 0 0;
    color: #2a81d7; }
  .left-actions a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
    flex: 0 0 auto;
    height: 100%;
    color: #7f8fa4;
    margin-right: 30px;
    text-decoration: none; }
    .left-actions a.active {
      margin-bottom: -3px;
      border-bottom: 3px solid #2a81d7; }
  .left-actions .connection-type-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: left;
    flex: 0 0 auto;
    height: 100%;
    color: #7f8fa4;
    margin-right: 30px;
    text-decoration: none; }
    .left-actions .connection-type-dropdown:first-child {
      margin-right: 5px; }
    .left-actions .connection-type-dropdown.active {
      margin-bottom: -3px;
      border-bottom: 3px solid #2a81d7; }
  .left-actions .action-divider {
    color: #7f8fa4;
    margin: 0px 30px; }
  .left-actions .fa {
    margin-left: 5px;
    color: #7f8fa4; }

.action-bar-content {
  flex-wrap: wrap; }

.right-actions {
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
  height: 36px; }
  .right-actions .search-container {
    display: inline-block;
    position: relative;
    min-width: 90px; }
    .right-actions .search-container .search {
      width: 100%;
      display: inline-block; }
  .right-actions a,
  .right-actions button {
    margin-left: 17px;
    flex: 0 0 auto; }

search {
  display: inline-block;
  position: relative; }
  search .icon-search, search .icon-error {
    position: absolute;
    right: 7px;
    margin-top: 8px;
    font-size: 20px;
    cursor: pointer;
    color: #ced0da;
    transform: scale(1.2); }

.subnav {
  width: 100%;
  margin-top: 15px; }
  .subnav .links {
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row; }
    .subnav .links a {
      position: relative;
      text-decoration: none;
      font-size: 14px; }
      .subnav .links a.active {
        border-bottom: 3px solid #2ea2f8; }
  .subnav.custom-subnav .links {
    margin: 0; }
  .subnav .badge {
    position: absolute;
    top: -10px;
    right: -15px;
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
    padding: 0px 4px;
    background-color: #ed1c24;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff; }

.app .sidebar-container mobile-sidebar {
  display: none; }

.mobile-visible {
  display: none; }

@media (max-width: 900px) {
  .desktop-visible {
    display: none; }
  .mobile-visible {
    display: block; }
  div.app {
    flex-direction: column;
    height: 100vh; }
    div.app .sidebar-container {
      z-index: 9999; }
      div.app .sidebar-container my-sidebar {
        display: none; }
      div.app .sidebar-container mobile-sidebar {
        display: block;
        left: calc(-100vw + 88px);
        position: absolute;
        transition: transform 0.3s; }
    div.app .main-component-container {
      transition: transform 0.3s; }
    div.app.sidebar-open {
      overflow-x: hidden; }
      div.app.sidebar-open .sidebar-container mobile-sidebar {
        transform: translateX(calc(100vw - 88px)); }
      div.app.sidebar-open .main-component-container {
        transform: translateX(calc(100vw - 88px)); }
  .main-component-container {
    flex: 1 1 auto; }
    .main-component-container > :last-child {
      height: 100%; }
  .edit-drawer, .side-drawer {
    height: 100%;
    top: 0;
    right: 0;
    max-width: 100%; }
  action-bar mat-toolbar-row .action-bar-content {
    padding-left: 16px;
    padding-right: 16px; }
  .dropdown.user.user .dropdown-button {
    padding-right: 8px; }
  .action-bar-content {
    margin-top: -15px;
    padding: 0 15px; }
    .action-bar-content .subnav {
      margin-top: 20px; }
      .action-bar-content .subnav .links {
        justify-content: space-between; }
        .action-bar-content .subnav .links a {
          margin-right: 0;
          padding-bottom: 10px; }
          .action-bar-content .subnav .links a.active {
            padding-bottom: 10px; }
    .action-bar-content .left-actions {
      margin-top: 0; }
    .action-bar-content .right-actions {
      margin-top: 15px; }
      .action-bar-content .right-actions :first-child {
        margin-left: 0; }
  .grid .list-table .row {
    width: calc(100VW - 30px) !important; }
  mat-toolbar-row {
    height: 44px; }
  .cdk-overlay-container {
    z-index: 10000; } }

@media (max-width: 600px) and (orientation: portrait) {
  mat-toolbar-row {
    height: 44px !important; } }

.sidebar {
  background-color: #012649;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: space-between;
  height: 100%; }
  .sidebar .sidebar-logo-container {
    width: 100%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(254, 254, 254, 0.15); }
    .sidebar .sidebar-logo-container img {
      height: 25px; }
  .sidebar .pinned-bottom {
    width: 100%;
    margin-bottom: 44px; }
  .sidebar [class^="icon-"] {
    margin-right: 15px;
    font-size: 20px; }
  .sidebar h2.menu-heading {
    padding-left: 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.17;
    color: #7f8fa4;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap; }
  .sidebar div.menu-heading {
    margin-top: 40px; }
    .sidebar div.menu-heading.first {
      margin-top: 0px; }
      .sidebar div.menu-heading.first h2.menu-heading {
        margin-top: 24px; }

.sidebar-container {
  background-color: #012649;
  overflow: hidden;
  transition: width 1s;
  width: 56px;
  flex: 0 0 auto; }
  .sidebar-container .nav-logo {
    object-fit: cover;
    width: 13px;
    object-position: 0;
    transition: width 0.5s; }
  .sidebar-container .menu-toggle-list .menu-section {
    height: 0;
    transition: height 0.4s;
    overflow: hidden; }
  .sidebar-container .menu-heading {
    transition: opacity 0.2s;
    opacity: 0; }
  .sidebar-container a {
    white-space: nowrap; }
  .sidebar-container:hover {
    width: 200px; }
    @media (min-width: 150px) {
      .sidebar-container:hover .application-version {
        margin-bottom: -32px;
        display: block; } }
    .sidebar-container:hover .nav-logo {
      width: 78px; }
    .sidebar-container:hover .menu-heading {
      opacity: 1; }
    .sidebar-container:hover .menu-toggle-list .menu-section {
      height: 50px; }

.gm-style-iw {
  padding-top: 16px; }

.gm-style-iw + div {
  right: 12px !important;
  top: 6px !important;
  background: url(/assets/img/icons/icn-close.svg) no-repeat;
  background-position: -5px -5px; }
  .gm-style-iw + div img {
    display: none; }

.gm-style > div:first-child > div:last-child > div:last-child > div:last-child {
  width: 140px !important; }
  .gm-style > div:first-child > div:last-child > div:last-child > div:last-child > div:first-child > div:nth-child(2) {
    width: 140px !important; }
  .gm-style > div:first-child > div:last-child > div:last-child > div:last-child > div:first-child > div:last-child {
    width: 138px !important; }

.locations .gm-style > div:first-child > div:last-child > div:last-child > div:last-child {
  width: 200px !important; }
  .locations .gm-style > div:first-child > div:last-child > div:last-child > div:last-child > div:first-child > div:nth-child(2) {
    width: 200px !important; }
  .locations .gm-style > div:first-child > div:last-child > div:last-child > div:last-child > div:first-child > div:last-child {
    width: 198px !important; }

.pac-container {
  z-index: 99999; }

my-date-picker .mydp .inlinedp {
  margin: 0 auto; }

my-date-picker .mydp .selbtngroup {
  height: 36px; }
  my-date-picker .mydp .selbtngroup button {
    margin-top: 2px;
    margin-bottom: -2px; }

my-date-picker.range-selected .mydp .selectiongroup .selbtngroup {
  width: 1px; }

my-date-picker.mobile-date-select .mydp {
  height: 100%; }
  my-date-picker.mobile-date-select .mydp .selectiongroup {
    height: 100%;
    background: whitesmoke;
    border: none; }
  my-date-picker.mobile-date-select .mydp .selection {
    background: none;
    height: 100% !important;
    padding: 0;
    color: #8e8e93;
    font-weight: 400;
    font-size: 17px !important; }

@supports (-ms-ime-align: auto) {
  my-date-picker .mydp .selbtngroup .mydpicon {
    top: 20px; } }

ag-grid-angular.ag-theme-material .ag-selection-checkbox {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  ag-grid-angular.ag-theme-material .ag-selection-checkbox span {
    margin-right: 0;
    top: 0; }
  ag-grid-angular.ag-theme-material .ag-selection-checkbox .ag-icon-checkbox-checked, ag-grid-angular.ag-theme-material .ag-selection-checkbox .ag-icon-checkbox-unchecked {
    cursor: pointer; }

ag-grid-angular.ag-theme-material .ag-cell-data-changed {
  background-color: rgba(0, 200, 83, 0.25) !important; }

.md-button-focus-overlay,
.md-button-ripple,
.mat-button-focus-overlay,
.mat-button-ripple {
  display: none; }

.mat-dialog-container {
  max-width: unset !important; }

.md-menu-panel,
.mat-menu-panel {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #dfe3e9; }
  .md-menu-panel .md-menu-content,
  .md-menu-panel .mat-menu-content,
  .md-menu-panel .mat-menu-content:not(:empty),
  .mat-menu-panel .md-menu-content,
  .mat-menu-panel .mat-menu-content,
  .mat-menu-panel .mat-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin-bottom: 0; }
    .md-menu-panel .md-menu-content a,
    .md-menu-panel .md-menu-content button,
    .md-menu-panel .mat-menu-content a,
    .md-menu-panel .mat-menu-content button,
    .md-menu-panel .mat-menu-content:not(:empty) a,
    .md-menu-panel .mat-menu-content:not(:empty) button,
    .mat-menu-panel .md-menu-content a,
    .mat-menu-panel .md-menu-content button,
    .mat-menu-panel .mat-menu-content a,
    .mat-menu-panel .mat-menu-content button,
    .mat-menu-panel .mat-menu-content:not(:empty) a,
    .mat-menu-panel .mat-menu-content:not(:empty) button {
      font-size: 14px;
      font-weight: 600;
      font-family: 'Source Sans Pro', sans-serif;
      text-align: left;
      color: #354052;
      height: 37px;
      line-height: 37px;
      padding: 0 14px;
      text-decoration: none;
      border: none;
      border-bottom: 1px solid #dfe3e9; }
      .md-menu-panel .md-menu-content a:last-child,
      .md-menu-panel .md-menu-content button:last-child,
      .md-menu-panel .mat-menu-content a:last-child,
      .md-menu-panel .mat-menu-content button:last-child,
      .md-menu-panel .mat-menu-content:not(:empty) a:last-child,
      .md-menu-panel .mat-menu-content:not(:empty) button:last-child,
      .mat-menu-panel .md-menu-content a:last-child,
      .mat-menu-panel .md-menu-content button:last-child,
      .mat-menu-panel .mat-menu-content a:last-child,
      .mat-menu-panel .mat-menu-content button:last-child,
      .mat-menu-panel .mat-menu-content:not(:empty) a:last-child,
      .mat-menu-panel .mat-menu-content:not(:empty) button:last-child {
        border-bottom: none; }
      .md-menu-panel .md-menu-content a:hover,
      .md-menu-panel .md-menu-content button:hover,
      .md-menu-panel .mat-menu-content a:hover,
      .md-menu-panel .mat-menu-content button:hover,
      .md-menu-panel .mat-menu-content:not(:empty) a:hover,
      .md-menu-panel .mat-menu-content:not(:empty) button:hover,
      .mat-menu-panel .md-menu-content a:hover,
      .mat-menu-panel .md-menu-content button:hover,
      .mat-menu-panel .mat-menu-content a:hover,
      .mat-menu-panel .mat-menu-content button:hover,
      .mat-menu-panel .mat-menu-content:not(:empty) a:hover,
      .mat-menu-panel .mat-menu-content:not(:empty) button:hover {
        background-color: #f1f4f8;
        color: #2a81d7; }

.cdk-column-actions {
  white-space: nowrap;
  min-width: 60px; }

.mat-header-cell button {
  text-transform: uppercase; }

.mat-form-field-appearance-legacy .mat-form-field-wrapper, .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0; }

mat-select .mat-select-value {
  max-width: 100%; }

mat-option.mat-option.mat-selected {
  font-weight: bold; }

mat-option.mat-option.mat-option-deselect {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87); }
  mat-option.mat-option.mat-option-deselect .mat-pseudo-checkbox-disabled {
    display: none; }
  mat-option.mat-option.mat-option-deselect:hover {
    background: rgba(0, 0, 0, 0.04); }

mat-input {
  width: 100%;
  max-width: 100%; }

.mat-form-field {
  display: inline;
  max-width: 100%; }
  .mat-form-field .mat-input-element {
    border: none;
    border-bottom: 1px solid #aaa;
    border-radius: 0;
    width: 100%; }
  .mat-form-field.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0;
    padding-top: 0; }
  .mat-form-field.mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0;
    border: none; }
  .mat-form-field .mat-form-field-infix {
    border: none;
    padding: 0; }

.mat-toolbar h1 {
  font: unset !important; }

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  color: #a3acaf;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
  background-color: #ffffff; }
  .gu-mirror .mat-sort-header-arrow {
    display: none; }

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important; }

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important; }

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20); }

.draggable * {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab; }

/**
 * Applies styles for users in high contrast mode. Note that this only applies
 * to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
 * attribute, however Chrome handles high contrast differently.
 * @param target Which kind of high contrast setting to target. Defaults to `active`, can be
 *    `white-on-black` or `black-on-white`.
 */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-badge-small .mat-badge-content {
  font-size: 6px; }

.mat-badge-large .mat-badge-content {
  font-size: 24px; }

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h5, .mat-typography h5 {
  font: 400 11.62px/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px; }

.mat-h6, .mat-typography h6 {
  font: 400 9.38px/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px; }

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif; }

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }
  .mat-body p, .mat-body-1 p, .mat-typography p {
    margin: 0 0 12px; }

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 56px;
  letter-spacing: -0.05em; }

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -0.02em; }

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -0.005em; }

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px; }

.mat-bottom-sheet-container {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400; }

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-card-title {
  font-size: 24px;
  font-weight: 400; }

.mat-card-subtitle,
.mat-card-content,
.mat-card-header .mat-card-title {
  font-size: 14px; }

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px; }

.mat-chip {
  font-size: 13px;
  line-height: 18px; }
  .mat-chip .mat-chip-trailing-icon.mat-icon,
  .mat-chip .mat-chip-remove.mat-icon {
    font-size: 18px; }

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-header-cell {
  font-size: 12px;
  font-weight: 500; }

.mat-cell, .mat-footer-cell {
  font-size: 14px; }

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-calendar-body {
  font-size: 13px; }

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500; }

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400; }

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif; }

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400; }

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field-wrapper {
  padding-bottom: 1.34375em; }

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125; }

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em; }
  .mat-form-field-prefix .mat-icon-button .mat-icon,
  .mat-form-field-suffix .mat-icon-button .mat-icon {
    height: 1.125em;
    line-height: 1.125; }

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent; }

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em; }

.mat-form-field-label {
  top: 1.34375em; }

.mat-form-field-underline {
  bottom: 1.34375em; }

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.66666667em;
  top: calc(100% - 1.79166667em); }

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0; }

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.33335333%; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em; }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.54166667em;
  top: calc(100% - 1.66666667em); }

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0; }

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em; }

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0; }

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em; }

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px; }
  .mat-grid-tile-header .mat-line,
  .mat-grid-tile-footer .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-grid-tile-header .mat-line:nth-child(n+2),
    .mat-grid-tile-footer .mat-line:nth-child(n+2) {
      font-size: 12px; }

input.mat-input-element {
  margin-top: -0.0625em; }

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px; }

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-select-trigger {
  height: 1.125em; }

.mat-slide-toggle-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500; }

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-step-label {
  font-size: 14px;
  font-weight: 400; }

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500; }

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0; }

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px; }

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px; }

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-list .mat-list-item, .mat-nav-list .mat-list-item, .mat-selection-list .mat-list-item {
  font-size: 16px; }
  .mat-list .mat-list-item .mat-line, .mat-nav-list .mat-list-item .mat-line, .mat-selection-list .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list .mat-list-item .mat-line:nth-child(n+2), .mat-selection-list .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list .mat-list-option, .mat-nav-list .mat-list-option, .mat-selection-list .mat-list-option {
  font-size: 16px; }
  .mat-list .mat-list-option .mat-line, .mat-nav-list .mat-list-option .mat-line, .mat-selection-list .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list .mat-list-option .mat-line:nth-child(n+2), .mat-nav-list .mat-list-option .mat-line:nth-child(n+2), .mat-selection-list .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list .mat-subheader, .mat-nav-list .mat-subheader, .mat-selection-list .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-list[dense] .mat-list-item, .mat-nav-list[dense] .mat-list-item, .mat-selection-list[dense] .mat-list-item {
  font-size: 12px; }
  .mat-list[dense] .mat-list-item .mat-line, .mat-nav-list[dense] .mat-list-item .mat-line, .mat-selection-list[dense] .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list[dense] .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list[dense] .mat-list-item .mat-line:nth-child(n+2), .mat-selection-list[dense] .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list[dense] .mat-list-option, .mat-nav-list[dense] .mat-list-option, .mat-selection-list[dense] .mat-list-option {
  font-size: 12px; }
  .mat-list[dense] .mat-list-option .mat-line, .mat-nav-list[dense] .mat-list-option .mat-line, .mat-selection-list[dense] .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list[dense] .mat-list-option .mat-line:nth-child(n+2), .mat-nav-list[dense] .mat-list-option .mat-line:nth-child(n+2), .mat-selection-list[dense] .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list[dense] .mat-subheader, .mat-nav-list[dense] .mat-subheader, .mat-selection-list[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500; }

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px; }

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif; }

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px; }

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500; }

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-tree-node {
  font-weight: 400;
  font-size: 14px; }

.mat-ripple {
  overflow: hidden; }

.mat-ripple.mat-ripple-unbounded {
  overflow: visible; }

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0); }
  @media screen and (-ms-high-contrast: active) {
    .mat-ripple-element {
      display: none; } }

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.cdk-overlay-container {
  position: fixed;
  z-index: 1000; }
  .cdk-overlay-container:empty {
    display: none; }

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000; }

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%; }

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0; }
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1; }
    @media screen and (-ms-high-contrast: active) {
      .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: 0.6; } }

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.288); }

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0; }

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px; }

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll; }

@keyframes cdk-text-field-autofill-start {
  /*!*/ }

@keyframes cdk-text-field-autofill-end {
  /*!*/ }

.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation-name: cdk-text-field-autofill-start; }

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation-name: cdk-text-field-autofill-end; }

textarea.cdk-textarea-autosize {
  resize: none; }

textarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important; }

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1); }

.mat-option {
  color: rgba(0, 0, 0, 0.87); }
  .mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04); }
  .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04); }
  .mat-option.mat-active {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87); }
  .mat-option.mat-option-disabled {
    color: rgba(0, 0, 0, 0.38); }

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2a81d7; }

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #4caf50; }

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336; }

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54); }
  .mat-pseudo-checkbox::after {
    color: #fafafa; }

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #4caf50; }

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #2a81d7; }

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336; }

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0; }

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.mat-theme-loaded-marker {
  display: none; }

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background: white; }
    .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
      color: rgba(0, 0, 0, 0.87); }

.mat-badge-content {
  color: white;
  background: #2a81d7; }

.mat-badge-accent .mat-badge-content {
  background: #4caf50;
  color: rgba(0, 0, 0, 0.87); }

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336; }

.mat-badge {
  position: relative; }

.mat-badge-hidden .mat-badge-content {
  display: none; }

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none; }

.mat-badge-content.mat-badge-active {
  transform: none; }

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px; }
  @media screen and (-ms-high-contrast: active) {
    .mat-badge-small .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px; }

.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px; }

.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px; }

[dir='rtl'] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px; }

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px; }

[dir='rtl'] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px; }

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px; }

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px; }

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px; }

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px; }

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px; }
  @media screen and (-ms-high-contrast: active) {
    .mat-badge-medium .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px; }

.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px; }

.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px; }

[dir='rtl'] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px; }

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px; }

[dir='rtl'] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px; }

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px; }

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px; }

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px; }

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px; }

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px; }
  @media screen and (-ms-high-contrast: active) {
    .mat-badge-large .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px; }

.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px; }

.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px; }

[dir='rtl'] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px; }

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px; }

[dir='rtl'] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px; }

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px; }

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px; }

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px; }

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px; }

.mat-bottom-sheet-container {
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent; }
  .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
    color: #2a81d7; }
  .mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
    color: #4caf50; }
  .mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
    color: #f44336; }
  .mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26); }
  .mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
    background-color: rgba(42, 129, 215, 0.12); }
  .mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
    background-color: rgba(76, 175, 80, 0.12); }
  .mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
    background-color: rgba(244, 67, 54, 0.12); }
  .mat-button[disabled] .mat-button-focus-overlay, .mat-icon-button[disabled] .mat-button-focus-overlay, .mat-stroked-button[disabled] .mat-button-focus-overlay {
    background-color: transparent; }
  .mat-button.mat-primary .mat-ripple-element, .mat-icon-button.mat-primary .mat-ripple-element, .mat-stroked-button.mat-primary .mat-ripple-element {
    background-color: rgba(42, 129, 215, 0.1); }
  .mat-button.mat-accent .mat-ripple-element, .mat-icon-button.mat-accent .mat-ripple-element, .mat-stroked-button.mat-accent .mat-ripple-element {
    background-color: rgba(76, 175, 80, 0.1); }
  .mat-button.mat-warn .mat-ripple-element, .mat-icon-button.mat-warn .mat-ripple-element, .mat-stroked-button.mat-warn .mat-ripple-element {
    background-color: rgba(244, 67, 54, 0.1); }

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white; }
  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    color: white; }
  .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    color: rgba(0, 0, 0, 0.87); }
  .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
    color: white; }
  .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26); }
  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    background-color: #2a81d7; }
  .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    background-color: #4caf50; }
  .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
    background-color: #f44336; }
  .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    background-color: rgba(0, 0, 0, 0.12); }
  .mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }
  .mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
    background-color: rgba(0, 0, 0, 0.1); }
  .mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }

.mat-icon-button.mat-primary .mat-ripple-element {
  background-color: rgba(42, 129, 215, 0.2); }

.mat-icon-button.mat-accent .mat-ripple-element {
  background-color: rgba(76, 175, 80, 0.2); }

.mat-icon-button.mat-warn .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.2); }

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38); }
  .mat-button-toggle .mat-button-toggle-focus-overlay {
    background-color: rgba(0, 0, 0, 0.12); }

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54); }

.mat-button-toggle-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.26); }
  .mat-button-toggle-disabled.mat-button-toggle-checked {
    background-color: #bdbdbd; }

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54); }

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54); }

.mat-checkbox-checkmark {
  fill: #fafafa; }

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important; }
  @media screen and (-ms-high-contrast: black-on-white) {
    .mat-checkbox-checkmark-path {
      stroke: #000 !important; } }

.mat-checkbox-mixedmark {
  background-color: #fafafa; }

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #2a81d7; }

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #4caf50; }

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336; }

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0; }

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0; }

.mat-checkbox-disabled .mat-checkbox-label {
  color: #b0b0b0; }

@media screen and (-ms-high-contrast: active) {
  .mat-checkbox-disabled {
    opacity: 0.5; } }

@media screen and (-ms-high-contrast: active) {
  .mat-checkbox-background {
    background: none; } }

.mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(42, 129, 215, 0.26); }

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(76, 175, 80, 0.26); }

.mat-checkbox:not(.mat-checkbox-disabled).mat-warn .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.26); }

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87); }
  .mat-chip.mat-standard-chip .mat-chip-remove {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.4; }
  .mat-chip.mat-standard-chip .mat-chip-remove:hover {
    opacity: 0.54; }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #2a81d7;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove:hover {
    opacity: 0.54; }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove:hover {
    opacity: 0.54; }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #4caf50;
  color: rgba(0, 0, 0, 0.87); }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove:hover {
    opacity: 0.54; }

.mat-table {
  background: white; }

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit; }

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12); }

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54); }

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87); }

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54); }

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54); }

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38); }

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12); }

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent; }

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38); }

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(0, 0, 0, 0.04); }

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18); }

.mat-calendar-body-selected {
  background-color: #2a81d7;
  color: white; }

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(42, 129, 215, 0.4); }

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white; }

.mat-datepicker-content {
  background-color: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
    background-color: #4caf50;
    color: rgba(0, 0, 0, 0.87); }
  .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(76, 175, 80, 0.4); }
  .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87); }
  .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
    background-color: #f44336;
    color: white; }
  .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(244, 67, 54, 0.4); }
  .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white; }

.mat-datepicker-toggle-active {
  color: #2a81d7; }
  .mat-datepicker-toggle-active.mat-accent {
    color: #4caf50; }
  .mat-datepicker-toggle-active.mat-warn {
    color: #f44336; }

.mat-dialog-container {
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12); }

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-keyboard-focused, .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-program-focused, .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
  background: rgba(0, 0, 0, 0.04); }

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true'])
.mat-expansion-panel-header:hover {
    background: white; } }

.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87); }

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-expansion-panel-header[aria-disabled='true'] {
  color: rgba(0, 0, 0, 0.26); }
  .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title,
  .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description {
    color: inherit; }

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6); }

.mat-hint {
  color: rgba(0, 0, 0, 0.6); }

.mat-form-field.mat-focused .mat-form-field-label {
  color: #2a81d7; }
  .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
    color: #4caf50; }
  .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
    color: #f44336; }

.mat-focused .mat-form-field-required-marker {
  color: #4caf50; }

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87); }

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #2a81d7; }
  .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
    background-color: #4caf50; }
  .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
    background-color: #f44336; }

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336; }
  .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
  .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
    color: #f44336; }

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336; }

.mat-error {
  color: #f44336; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54); }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02); }

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent; }

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12); }

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87); }

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #2a81d7; }

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #4caf50; }

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336; }

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336; }

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06); }

.mat-icon.mat-primary {
  color: #2a81d7; }

.mat-icon.mat-accent {
  color: #4caf50; }

.mat-icon.mat-warn {
  color: #f44336; }

.mat-input-element:disabled {
  color: rgba(0, 0, 0, 0.38); }

.mat-input-element {
  caret-color: #2a81d7; }
  .mat-input-element::placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element::-moz-placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.42); }

.mat-accent .mat-input-element {
  caret-color: #4caf50; }

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336; }

.mat-list .mat-list-item, .mat-nav-list .mat-list-item, .mat-selection-list .mat-list-item {
  color: rgba(0, 0, 0, 0.87); }

.mat-list .mat-list-option, .mat-nav-list .mat-list-option, .mat-selection-list .mat-list-option {
  color: rgba(0, 0, 0, 0.87); }

.mat-list .mat-subheader, .mat-nav-list .mat-subheader, .mat-selection-list .mat-subheader {
  color: rgba(0, 0, 0, 0.54); }

.mat-list-item-disabled {
  background-color: #eeeeee; }

.mat-list-option:hover, .mat-list-option.mat-list-item-focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item.mat-list-item-focus {
  background: rgba(0, 0, 0, 0.04); }

.mat-menu-panel {
  background: white; }

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87); }
  .mat-menu-item[disabled], .mat-menu-item[disabled]::after {
    color: rgba(0, 0, 0, 0.38); }

.mat-menu-item .mat-icon:not([color]),
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04); }

.mat-paginator {
  background: white; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54); }

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54); }

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54); }

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-progress-bar-background {
  fill: #bfd9f3; }

.mat-progress-bar-buffer {
  background-color: #bfd9f3; }

.mat-progress-bar-fill::after {
  background-color: #2a81d7; }

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #1b5e20; }

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #1b5e20; }

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #4caf50; }

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2; }

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2; }

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336; }

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #2a81d7; }

.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #4caf50; }

.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336; }

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54); }

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2a81d7; }

.mat-radio-button.mat-primary .mat-radio-inner-circle {
  background-color: #2a81d7; }

.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(42, 129, 215, 0.26); }

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #4caf50; }

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #4caf50; }

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(76, 175, 80, 0.26); }

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336; }

.mat-radio-button.mat-warn .mat-radio-inner-circle {
  background-color: #f44336; }

.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.26); }

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38); }

.mat-select-content, .mat-select-panel-done-animating {
  background: white; }

.mat-select-value {
  color: rgba(0, 0, 0, 0.87); }

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42); }

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38); }

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54); }

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12); }

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #2a81d7; }

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #4caf50; }

.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336; }

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336; }

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38); }

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-drawer.mat-drawer-push {
    background-color: white; }

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6); }

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #4caf50; }

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(76, 175, 80, 0.5); }

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.06); }

.mat-slide-toggle .mat-ripple-element {
  background-color: rgba(76, 175, 80, 0.12); }

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #2a81d7; }

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(42, 129, 215, 0.5); }

.mat-slide-toggle.mat-primary:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.06); }

.mat-slide-toggle.mat-primary .mat-ripple-element {
  background-color: rgba(42, 129, 215, 0.12); }

.mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #f44336; }

.mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.5); }

.mat-slide-toggle.mat-warn:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.06); }

.mat-slide-toggle.mat-warn .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.12); }

.mat-disabled .mat-slide-toggle-thumb {
  background-color: #bdbdbd; }

.mat-disabled .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.1); }

.mat-slide-toggle-thumb {
  background-color: #fafafa; }

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #2a81d7; }

.mat-primary .mat-slider-thumb-label-text {
  color: white; }

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #4caf50; }

.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87); }

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f44336; }

.mat-warn .mat-slider-thumb-label-text {
  color: white; }

.mat-slider-focus-ring {
  background-color: rgba(76, 175, 80, 0.2); }

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12); }

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87); }

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent; }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26); }

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7); }

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent); }

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent); }

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04); }

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.38); }

.mat-step-header .mat-step-icon {
  background-color: #2a81d7;
  color: white; }

.mat-step-header .mat-step-icon-not-touched {
  background-color: rgba(0, 0, 0, 0.38);
  color: white; }

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87); }

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white; }

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12); }

.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-sort-header-arrow {
  color: #757575; }

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none; }

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87); }
  .mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
    color: rgba(0, 0, 0, 0.38); }

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87); }

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-tab-group[class*='mat-background-'] .mat-tab-header,
.mat-tab-nav-bar[class*='mat-background-'] {
  border-bottom: none;
  border-top: none; }

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(191, 217, 243, 0.3); }

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #2a81d7; }

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(27, 94, 32, 0.3); }

.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #4caf50; }

.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87); }

.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3); }

.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336; }

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(191, 217, 243, 0.3); }

.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links {
  background-color: #2a81d7; }

.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(27, 94, 32, 0.3); }

.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links {
  background-color: #4caf50; }

.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: rgba(0, 0, 0, 0.87); }
  .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
    color: rgba(0, 0, 0, 0.4); }

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87); }

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4); }

.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12); }

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3); }

.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links {
  background-color: #f44336; }

.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87); }
  .mat-toolbar.mat-primary {
    background: #2a81d7;
    color: white; }
  .mat-toolbar.mat-accent {
    background: #4caf50;
    color: rgba(0, 0, 0, 0.87); }
  .mat-toolbar.mat-warn {
    background: #f44336;
    color: white; }
  .mat-toolbar .mat-form-field-underline,
  .mat-toolbar .mat-form-field-ripple,
  .mat-toolbar .mat-focused .mat-form-field-ripple {
    background-color: currentColor; }
  .mat-toolbar .mat-form-field-label,
  .mat-toolbar .mat-focused .mat-form-field-label,
  .mat-toolbar .mat-select-value,
  .mat-toolbar .mat-select-arrow,
  .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
    color: inherit; }
  .mat-toolbar .mat-input-element {
    caret-color: currentColor; }

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9); }

.mat-tree {
  background: white; }

.mat-tree-node {
  color: rgba(0, 0, 0, 0.87); }

.mat-snack-bar-container {
  background: #323232;
  color: white; }

.mat-simple-snackbar-action {
  color: #4caf50; }

body {
  background: #fff;
  font-size: 14px; }

html, body {
  height: 100%;
  margin: 0; }

.app {
  min-height: 390px;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal; }

.app-content {
  background-color: #f1f4f6;
  height: 100%;
  width: 100%; }

* * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased; }

h1 {
  font-size: 20px;
  font-weight: 400;
  color: #354052; }

h2 {
  font-size: 18px;
  color: #354052;
  font-weight: 400; }

h3 {
  font-size: 16px;
  color: #354052; }

p {
  font-size: 14px;
  color: #354052;
  font-weight: 100; }

.smaller {
  font-weight: 500;
  color: #354052; }

.additional {
  font-weight: 400;
  color: #7f8fa4; }

.description {
  color: #7f8fa4;
  font-size: 12px; }

.missing-required:before {
  content: '--';
  font-size: 14px;
  font-weight: 600;
  color: #ed1c24; }

.mat-tab-label:focus {
  background: transparent; }

.mat-tab-label .mat-ripple-background, .mat-tab-label .mat-ripple-foreground {
  display: none; }

.organization-actions .dropdown .dropdown-menu {
  right: 0;
  width: 100px; }

.menu-toggle-list.expanded a {
  background: #154b80; }

[my-login], [ruckit-connection], [ruckit-team], [ruckit-users-list], [ruckit-trips] {
  display: block;
  height: 100VH; }

.full-height {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto; }

new-job {
  height: 100VH;
  overflow-y: scroll; }

ruckit-dispatch action-bar mat-toolbar {
  margin-bottom: 0; }

mat-toolbar {
  min-height: unset !important; }

.main-component-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  overflow: hidden; }
  .main-component-container > :last-child {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100VH; }

.upload-image-dropzone {
  width: 370px;
  height: 194px;
  border-radius: 4px;
  margin-bottom: 30px;
  background-color: #ffffff;
  border: solid 1px rgba(255, 255, 255, 0.25);
  padding: 3px;
  position: relative;
  color: #7f8fa4;
  text-align: center; }
  .upload-image-dropzone.round {
    border-radius: 50%;
    width: 250px;
    height: 250px;
    max-height: 250px; }
    .upload-image-dropzone.round label {
      border-radius: 50%; }
    .upload-image-dropzone.round.filled {
      height: 250px; }
      .upload-image-dropzone.round.filled label {
        display: none;
        height: 80px;
        z-index: 1;
        padding-top: 20px; }
      .upload-image-dropzone.round.filled:hover label {
        display: block; }
    .upload-image-dropzone.round img {
      width: 100%;
      height: 100%; }
  .upload-image-dropzone.filled {
    padding: 0;
    min-height: 100%;
    display: flex;
    align-items: center;
    height: auto;
    overflow: hidden; }
    .upload-image-dropzone.filled:hover label {
      display: block; }
    .upload-image-dropzone.filled label {
      position: absolute;
      display: none;
      bottom: 0;
      height: 40px;
      background: linear-gradient(rgba(0, 0, 0, 0) 0%, black 100%);
      border: none;
      color: #fff;
      margin: 0; }
      .upload-image-dropzone.filled label .icon-upload {
        color: #fff;
        display: inline-block;
        font-size: 25px;
        vertical-align: middle;
        margin-right: 4px; }
  .upload-image-dropzone img {
    width: 100%;
    height: 100%; }
  .upload-image-dropzone label {
    height: 100%;
    width: 100%;
    border: dashed 3px #ced0da;
    content: '';
    display: block;
    padding: 40px;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    color: #7f8fa4; }
    .upload-image-dropzone label .icon-upload {
      color: #e2e3e9;
      font-size: 47px;
      display: block;
      text-align: center; }
  .upload-image-dropzone input {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    position: absolute; }

.sidebar-container {
  overflow: hidden;
  transition: width 0.5s;
  width: 56px;
  flex: 0 0 auto; }
  .sidebar-container .nav-logo {
    object-fit: cover;
    width: 13px;
    object-position: 0;
    transition: width 0.5s; }
  .sidebar-container .menu-toggle-list .menu-section {
    height: 0;
    transition: height 0.4s;
    overflow: hidden; }
  .sidebar-container .menu-heading {
    transition: opacity 0.2s;
    opacity: 0; }
  .sidebar-container a {
    white-space: nowrap; }
  .sidebar-container:hover {
    width: 200px; }
    .sidebar-container:hover .nav-logo {
      width: 78px; }
    .sidebar-container:hover .menu-heading {
      opacity: 1; }
    .sidebar-container:hover .menu-toggle-list .menu-section {
      height: 50px; }

.btn-filters {
  margin-left: 20px; }
  .btn-filters i {
    color: #c7d2e0; }

.cdk-focus-trap-content {
  height: 100%; }

.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg); }

.rotate-270 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg); }

@media print {
  body {
    height: auto; }
  body * {
    visibility: hidden;
    overflow-y: visible;
    pointer-events: auto; }
  .cdk-overlay-container {
    position: absolute; } }
